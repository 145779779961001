import axiosService from '@/libs/axiosService'
import { ModelPayload } from '@/models/Payloads'
import { VehiclePayload } from '@/models/Vehicle'

export const getVehicleCategories = (payload: VehiclePayload) => axiosService({
  url: `/admin/vehicle/categories/${payload.vehicleCategory}`,
  method: 'get',
  params: payload.params
})

export const getAdminVehicles = (payload: ModelPayload) => axiosService({
  url: '/admin/vehicles',
  method: 'get',
  params: payload.params
})

export const getAdminVehicle = (payload: ModelPayload) => axiosService({
  url: `/admin/vehicle/${payload.modelUid}`,
  method: 'get'
})

export const createAdminVehicle = (payload: ModelPayload) => axiosService({
  url: '/admin/vehicles',
  method: 'post',
  data: payload.data
})
export const deleteAdminVehicle = (payload: ModelPayload) => axiosService({
  url: `/admin/vehicle/${payload.modelUid}`,
  method: 'delete'
})

export const updateAdminVehicle = (payload: ModelPayload) => axiosService({
  url: `/admin/vehicle/${payload.modelUid}`,
  method: 'patch',
  data: payload.data
})
