import { Action, Module, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { Pagination } from '@/models/General'
import { ModelPayload } from '@/models/Payloads'
import { VehicleItem } from '@/models/Vehicle'
import { createAdminVehicle, getAdminVehicle, getAdminVehicles, updateAdminVehicle } from '@/api/vehicles'

export const VEHICLE_MODULE_NAME = 'vehicle'

@Module({
  namespaced: true,
  store,
  name: VEHICLE_MODULE_NAME
})
export class VehicleModule extends VuexModule {
  constructor () {
    super(VuexModule)
  }

  @Action({ rawError: true })
  public async fetchAdminVehicles (payload: ModelPayload): Promise<Pagination> {
    return new Promise((resolve, reject) => {
      getAdminVehicles(payload)
        .then(response => {
          if (response && response.data && response.data.vehicles) {
            const vehiclesPagination: Pagination = response.data.vehicles
            resolve(vehiclesPagination)
          }
        })
        .catch(error => {
          // console.log .('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async fetchAdminVehicle (payload: ModelPayload): Promise<VehicleItem> {
    return new Promise((resolve, reject) => {
      getAdminVehicle(payload)
        .then(response => {
          if (response && response.data && response.data.vehicle) {
            const vehicleItem: VehicleItem = response.data.vehicle
            resolve(vehicleItem)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async createAdminVehicle (payload: ModelPayload): Promise<VehicleItem> {
    return new Promise((resolve, reject) => {
      createAdminVehicle(payload)
        .then(response => {
          if (response && response.data && response.data.vehicle) {
            const vehicleItem: VehicleItem = response.data.vehicle
            resolve(vehicleItem)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async updateAdminVehicle (payload: ModelPayload): Promise<VehicleItem> {
    return new Promise((resolve, reject) => {
      updateAdminVehicle(payload)
        .then(response => {
          if (response && response.data && response.data.vehicle) {
            const vehicleItem: VehicleItem = response.data.vehicle
            resolve(vehicleItem)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
}
